import "../public/styles/styles.css";
import NProgress from "nprogress";
import Router from "next/router";
import { DefaultSeo } from "next-seo";
import { SessionProvider } from "next-auth/react";
import { useEffect } from "react";
import TagManager from "react-gtm-module";

export default function App({ Component, pageProps }) {
    useEffect(() => {
        TagManager.initialize({
            gtmId: process.env.NEXT_PUBLIC_TAG_MANAGER_ID,
        });
        NProgress.configure({ showSpinner: false });
        Router.events.on("routeChangeStart", () => NProgress.start());
        Router.events.on("routeChangeComplete", () => {
            NProgress.done();
        });
        Router.events.on("routeChangeError", () => NProgress.done());
    }, []);

    return (
        <SessionProvider session={pageProps.session}>
            <DefaultSeo
                title="Dzobs.com | IT Poslovi i iskustva"
                description="Najveća baza IT poslova i IT firmi u Bosni i Hercegovini"
                openGraph={{
                    type: "website",
                    site_name: "Dzobs",
                    images: [
                        {
                            url: "https://www.dzobs.com/social.png",
                        },
                    ],
                }}
                // twitter={{
                //     handle: "@dzobs",
                //     site: "@dzobs",
                //     cardType: "summary_large_image",
                // }}
            />
            <Component {...pageProps} />
        </SessionProvider>
    );
}
